import { render, staticRenderFns } from "./statustable.vue?vue&type=template&id=8defcbbe&scoped=true"
import script from "./statustable.vue?vue&type=script&lang=js"
export * from "./statustable.vue?vue&type=script&lang=js"
import style0 from "./statustable.vue?vue&type=style&index=0&id=8defcbbe&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8defcbbe",
  null
  
)

export default component.exports